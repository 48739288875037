<template>
  <section>
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs nav-justified hidden-xs">
        <li class="active">
          <a href="#tab_a" data-toggle="tab"
            ><i class="fa fa-info-circle"></i>
            {{ $tc("titles.connection_instructions", 1) }}
          </a>
        </li>
        <li>
          <a href="#tab_b" data-toggle="tab"
            ><i class="fa fa-send"></i> {{ $tc("message", 1) }} (payload)
          </a>
        </li>
        <li>
          <a href="#tab_c" data-toggle="tab"
            ><i class="fa fa-code"></i> {{ $tc("example", 2) }}
          </a>
        </li>
      </ul>
      <ul class="nav nav-tabs hidden-sm hidden-md hidden-lg">
        <li class="active">
          <a href="#tab_a" data-toggle="tab"
            ><i class="fa fa-info-circle"></i> {{ $t("info") }}
          </a>
        </li>
        <li>
          <a href="#tab_b" data-toggle="tab"
            ><i class="fa fa-send"></i> {{ $tc("message", 1) }} (payload)
          </a>
        </li>
        <li>
          <a href="#tab_c" data-toggle="tab"
            ><i class="fa fa-code"></i> {{ $tc("example", 2) }}
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div id="tab_a" class="tab-pane active">
          <template v-if="showInfoPanel">
            <form
              class="inline-form no-select"
              style="padding: 10px;margin: -20px -4px 10px -4px;"
            >
              <label class="checkbox-inline" for="host_ip">
                <input type="checkbox" v-model="host_ip" id="host_ip" />
                Broker IP
              </label>
              <label class="checkbox-inline" for="secure_connection">
                <input
                  type="checkbox"
                  v-model="secure_connection"
                  id="secure_connection"
                  name="secure_connection"
                />
                {{ $t("secure_connection") }}
              </label>
              <MqttAccessTokenSelector
                @changed="token = $event"
                :connector="connector"
                :inline="true"
                ref="tokenSelector"
              >
                <template #addonButton>
                  <span
                    :title="$t('edit')"
                    class="btn btn-default btn-sm"
                    style="border-left:0;margin-top:-2px"
                    @click.stop.prevent="showInfoPanel = !showInfoPanel"
                  >
                    <i class="fa fa-pencil"></i>
                  </span>
                </template>
              </MqttAccessTokenSelector>
            </form>
            <!-- begin configuration -->
            <div>
              <div class="row">
                <div class="col-lg-12">
                  <label for="">
                    {{ $tc("configuration", 1) }}
                  </label>
                  <label class="checkbox-inline pull-right">
                    {{ $t("format") }}:
                    <span
                      class="btn btn-xs"
                      @click.stop="planText = true"
                      :class="{'text-primary ': planText}"
                    >
                      TEXT
                    </span>
                    <span
                      class="btn btn-xs"
                      @click.stop="planText = false"
                      :class="{'text-primary ': !planText}"
                    >
                      JSON
                    </span>
                  </label>
                  <div class="code-container">
                    <div class="toolbar">
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="
                          downloadContent(
                            `mqtt_settings_${connector.id}.${
                              planText ? 'txt' : 'json'
                            }`,
                            planText ? txtConfig : jsonConfig
                          )
                        "
                      >
                        <i class="fa fa-download"></i>
                      </span>
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="
                          copyToClipboard(planText ? txtConfig : jsonConfig)
                        "
                      >
                        <i class="fa fa-copy"></i>
                      </span>
                    </div>
                    <pre>{{ planText ? txtConfig : jsonConfig }}</pre>
                    <!-- <pre>{{ connector }}</pre> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end configuration -->
          </template>
          <template v-else>
            <FormContractTokens>
              <template #title>
                <div style="display:flex;justify-content:space-between">
                  <div>
                    <span
                      class="btn btn-xs btn-default"
                      @click.stop.prevent="showInfoPanel = true"
                    >
                      <i class="fa fa-arrow-left"></i> {{ $t("back") }}
                    </span>
                  </div>
                  <div class="text-center">
                    <span class="clicable no-select label">
                      {{ $tc("access_key", 2) }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="btn btn-default btn-xs"
                      @click.stop.prevent="showInfoPanel = true"
                    >
                      <i class="fa fa-close"></i>
                    </span>
                  </div>
                </div>
              </template>
            </FormContractTokens>
          </template>
        </div>
        <div id="tab_b" class="tab-pane">
          <!-- begin payload for publish -->
          <div>
            <div class="row">
              <div class="col-md-6">
                <label for="">{{ $t("data_list") }}</label>
                <SearchableTable
                  style="margin-top:5px"
                  :fields="dataFields"
                  :items="dataList"
                  :multiSelection="selectedData"
                  @select="onSelectData"
                >
                </SearchableTable>
              </div>
              <div class="col-md-6">
                <div style="height:32px;" class="hidden-md hidden-lg"></div>
                <label for="" style="width:100%">
                  MQTT - {{ $t("message") }}
                  <span
                    class="pull-right no-select clicable"
                    @click.stop.prevent="pubConnectorTopic = !pubConnectorTopic"
                    style="height:22px"
                    v-if="selectedDataList.length"
                  >
                    {{ $t("topic") }} {{ $tc("of", 1, {gender: "o"}) }}
                    {{ $tc("connector", 1) }}
                    <i
                      :class="
                        pubConnectorTopic
                          ? 'fa fa-check-square-o'
                          : 'fa fa-square-o'
                      "
                      style="vertical-align:middle"
                    ></i>
                  </span>
                </label>
                <div class="form-group" style="margin-top:6px">
                  <div class="input-group">
                    <div
                      class="input-group-addon"
                      :style="{
                        'background-color': !selectedDataList.length
                          ? '#eee'
                          : 'transparent'
                      }"
                    >
                      {{ $t("modelo") }}
                    </div>
                    <select
                      class="form-control"
                      v-model="selectedMessageModel"
                      :disabled="!selectedDataList.length"
                    >
                      <option
                        :value="item.id"
                        v-for="item in messageModelList"
                        :key="item.id"
                        >{{ item.label }}</option
                      >
                    </select>
                  </div>
                </div>
                <div v-if="pubConnectorTopic || !selectedDataList.length">
                  <div style="display:flex;justify-content:space-between">
                    <span
                      class="clicable"
                      @click.stop.prevent="copyToClipboard(`${connectorTopic}`)"
                    >
                      <span class="text-bold"> {{ $t("topic") }}: </span>
                      {{ `${connectorTopic}` }}
                      <span class="btn btn-xs">
                        <span class="i fa fa-copy"></span>
                      </span>
                    </span>
                  </div>
                  <div class="code-container">
                    <div class="toolbar">
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="
                          downloadContent(
                            `mqtt_payload_${connector.id}.json`,
                            jsonPayload
                          )
                        "
                      >
                        <i class="fa fa-download"></i>
                      </span>
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="copyToClipboard(jsonPayload)"
                      >
                        <i class="fa fa-copy"></i>
                      </span>
                    </div>
                    <pre>{{
                      selectedDataList.length ? jsonPayload : $t("not_selected")
                    }}</pre>
                  </div>
                </div>
                <div v-else>
                  <template v-for="(item, ix) in jsonListPayload">
                    <div :key="ix">
                      <div style="display:flex;justify-content:space-between">
                        <span
                          class="clicable"
                          @click.stop.prevent="copyToClipboard(`${item.topic}`)"
                        >
                          <span class="text-bold"> {{ $t("topic") }}: </span>
                          {{ item.topic }}
                          <span class="btn btn-xs">
                            <span class="i fa fa-copy"></span>
                          </span>
                        </span>
                      </div>
                      <div class="code-container">
                        <div class="toolbar">
                          <span
                            class="btn btn-xs text-warning"
                            @click.stop.prevent="
                              downloadContent(
                                `mqtt_payload_${connector.id}-${item.topic}.json`,
                                item.message
                              )
                            "
                          >
                            <i class="fa fa-download"></i>
                          </span>
                          <span
                            class="btn btn-xs text-warning"
                            @click.stop.prevent="copyToClipboard(item.message)"
                          >
                            <i class="fa fa-copy"></i>
                          </span>
                        </div>
                        <pre class="short">{{ item.message }}</pre>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- end payload for publish -->
        </div>
        <div id="tab_c" class="tab-pane">
          <!-- begin mosquitto_pub example -->
          <div v-if="pubConnectorTopic">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <label for="" style="width:100%"
                    >{{ $tc("publish", 2) }}
                    <div
                      class="pull-right text-primary"
                      style="font-weight:500"
                    >
                      TXT
                    </div>
                  </label>
                  <div class="code-container">
                    <div class="toolbar">
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="
                          downloadContent(
                            `mosquito_pub_${connector.id}.txt`,
                            mosquittoPubExample
                          )
                        "
                      >
                        <i class="fa fa-download"></i>
                      </span>
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="
                          copyToClipboard(mosquittoPubExample)
                        "
                      >
                        <i class="fa fa-copy"></i>
                      </span>
                    </div>
                    <pre>{{ mosquittoPubExample }}</pre>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <label for="" style="width:100%"
                  >{{ $tc("subscribe", 2) }}
                  <div class="pull-right text-primary" style="font-weight:500">
                    TXT
                  </div>
                </label>
                <div class="code-container">
                  <div class="toolbar">
                    <span
                      class="btn btn-xs text-warning"
                      @click.stop.prevent="
                        downloadContent(
                          `mosquito_pub_${connector.id}.txt`,
                          mosquittoSubExample
                        )
                      "
                    >
                      <i class="fa fa-download"></i>
                    </span>
                    <span
                      class="btn btn-xs text-warning"
                      @click.stop.prevent="copyToClipboard(mosquittoSubExample)"
                    >
                      <i class="fa fa-copy"></i>
                    </span>
                  </div>
                  <pre>{{ mosquittoSubExample }}</pre>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="row"
              v-for="(item, ix) in mosquittoPubListExample"
              :key="ix"
            >
              <div class="col-lg-12">
                <div>
                  <div class="code-container">
                    <div class="toolbar">
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="
                          downloadContent(
                            `mosquito_pub_${connector.id}.txt`,
                            item
                          )
                        "
                      >
                        <i class="fa fa-download"></i>
                      </span>
                      <span
                        class="btn btn-xs text-warning"
                        @click.stop.prevent="copyToClipboard(item)"
                      >
                        <i class="fa fa-copy"></i>
                      </span>
                    </div>
                    <pre>{{ item }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end mosquitto_pub example -->
        </div>
      </div>
    </div>
    <div class="footer">
      <span
        v-if="secure_connection"
        class="btn btn-default btn-xs btn-warning"
        @click.stop.prevent="onCertificateDownload()"
        :title="`${$t('download')} ${$t('certificate')}`"
        style=""
      >
        <b>
          <i class="fa fa-download"></i>
          {{ $t("download") }} {{ $t("certificate").toLowerCase() }}
        </b>
      </span>
    </div>
  </section>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
import MqttAccessTokenSelector from "@/components/registration/mqtt-access-token-selector.vue";
import FormContractTokens from "@/components/registration/form-contract-tokens.vue";
export default {
  name: "MqttSettingsViewer",
  components: {
    SearchableTable,
    MqttAccessTokenSelector,
    FormContractTokens
  },
  props: {
    connector: {
      type: Object,
      required: false,
      default: () => null
    },
    protocol: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      planText: false,
      host_ip: true,
      secure_connection: false,
      token: "",
      selectedData: {
        enabled: true,
        key: "id",
        values: []
      },
      perMessage: false,
      selectedMessageModel: "standard",
      pubConnectorTopic: true,
      showInfoPanel: true
    };
  },
  computed: {
    parsedHost() {
      return this.host_ip
        ? this.protocol.access_host
        : this.protocol.access_url;
    },
    parsedPort() {
      return this.secure_connection
        ? this.protocol.connector_access_secure_port
        : this.protocol.connector_access_port;
    },
    clientId() {
      return this.connector.mqtt_topic_prefix;
    },
    connectorTopic() {
      return this.connector.mqtt_topic_prefix;
    },
    txtConfig() {
      let lst = [
        ["host", this.parsedHost],
        ["port", this.parsedPort],
        ["username", this.token],
        ["client_id", this.clientId],
        ["connector_topic", this.connectorTopic]
      ];
      return lst.map((i) => `${i[0]}=${i[1]}`).join("\n");
    },
    jsonConfig() {
      let json = {
        host: this.parsedHost,
        port: this.parsedPort,
        username: this.token,
        client_id: this.clientId,
        connector_topic: this.connectorTopic
      };
      return JSON.stringify(json, undefined, 2);
    },
    rawJsonPayload() {
      let entry = null;
      let now = new Date().getTime();
      const topic = ({device, label}) =>
        device?.label ? `${device.label}/${label}` : label;
      switch (this.selectedMessageModel) {
        case "timestamp_unique": {
          entry = {
            timestamp: now
          };
          this.selectedDataList.forEach((i) => {
            entry[topic(i)] = 0;
          });
          break;
        }
        case "timestamp_by_data": {
          entry = this.selectedDataList.length ? {} : null;
          this.selectedDataList.forEach((i) => {
            entry[topic(i)] = {
              timestamp: now + 1,
              value: 0
            };
          });
          break;
        }
        default: {
          entry = this.selectedDataList.length ? {} : null;
          this.selectedDataList.forEach((i) => {
            entry[topic(i)] = 0;
          });
        }
      }
      return entry;
    },
    rawListPayload() {
      let now = new Date().getTime();
      const topic = ({device, label}) =>
        device?.label
          ? `${this.connectorTopic}/${device.label}/${label}`
          : `${this.connectorTopic}/${label}`;
      let lst = (this.selectedDataList || []).map((i) => {
        switch (this.selectedMessageModel) {
          case "timestamp_by_data": {
            return {
              topic: topic(i),
              message: {
                timestamp: now,
                value: 0
              }
            };
          }
          default: {
            return {
              topic: topic(i),
              message: {
                value: 0
              }
            };
          }
        }
      });
      return lst;
    },
    jsonListPayload() {
      return this.rawListPayload.map((i) => ({
        topic: i.topic,
        message: JSON.stringify(i.message, undefined, 2)
      }));
    },
    jsonPayload() {
      return JSON.stringify(this.rawJsonPayload, undefined, 2);
    },
    mosquittoCommon() {
      return [
        this.host_ip ? `-h ${this.parsedHost}` : `-h "${this.parsedHost}"`,
        `-p ${this.parsedPort}`,
        `-i "${this.connectorTopic}"`,
        `-u "${this.token}"`,
        ...(this.secure_connection ? [`--cafile ./ca.pem`] : [])
      ];
    },
    mosquittoPubExample() {
      let lst = [`mosquitto_pub`, ...this.mosquittoCommon];
      lst.push(
        `-t "${this.connectorTopic}"`,
        `-m '${JSON.stringify(this.rawJsonPayload)}'`
      );
      return lst.join(" \\\n");
    },
    mosquittoPubListExample() {
      let lst;
      return this.rawListPayload.map((i) => {
        lst = [`mosquitto_pub`, ...this.mosquittoCommon];
        lst.push(`-t "${i.topic}"`, `-m '${JSON.stringify(i.message)}'`);
        return lst.join(" \\\n");
      });
    },
    mosquittoSubExample() {
      let lst = [
        `mosquitto_sub`,
        ...this.mosquittoCommon,
        `-t "${this.connectorTopic}/#"`
      ];
      return lst.join(" \\\n");
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"].filter(
        ({clp_id, is_local}) =>
          !is_local && parseInt(clp_id) === parseInt(this.connector.id)
      );
    },
    selectedDataList() {
      return (this?.dataList || []).filter(
        ({id}) => (this?.selectedData?.values || []).indexOf(parseInt(id)) >= 0
      );
    },
    dataFields() {
      const hint = (item) => this.$root.$formatter.describe(item, {});
      let devices = (this.dataList || []).reduce((a, {device}) => {
        a.add(device.id);
        return a;
      }, new Set());
      let fields = [
        {
          name: "id",
          title: "ID",
          visible: true,
          hint: hint
        },
        {
          name: "device",
          title: "device",
          visible: devices.size > 1,
          parser: ({device}) => device?.name || "",
          hint: hint
        },
        {
          name: "name",
          title: "name",
          visible: true,
          hint: hint
        },
        {
          name: "type",
          title: "type",
          visible: false,
          hint: hint,
          parser(item) {
            return item?.memory_type?.name || "";
          }
        }
      ];
      return fields;
    },
    messageModelList() {
      return this.pubConnectorTopic
        ? [
            {
              id: "standard",
              label: this.$t("standard")
            },
            {
              id: "timestamp_unique",
              label: this.$t("titles.timestamp_unique")
            },
            {
              id: "timestamp_by_data",
              label: this.$t("titles.timestamp_per_data")
            }
          ]
        : [
            {
              id: "standard",
              label: this.$t("standard")
            },
            {
              id: "timestamp_by_data",
              label: this.$t("timestamp")
            }
          ];
    }
  },
  methods: {
    copyToClipboard(text) {
      this.clipboardText = text;
      this.$utils.toClipboard(text);
      let infoHtml = `${this.$t("item_copied")}`;
      let options = {
        type: "success",
        icon: "copy",
        iconPack: "fontawesome",
        singleton: true,
        duration: 10000, // 10s
        position: "bottom-right",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      };
      this.$toasted.clear();
      this.$toasted.show(infoHtml, options);
    },
    downloadContent(filename, content) {
      this.$utils.download(content, "plan/text", filename);
    },
    onCertificateDownload() {
      this.$utils.download(this.$root.config.mqtt.cert, "ca.pem");
    },
    onSelectData($event) {
      if (Array.isArray($event)) {
        this.selectedData.values = $event;
      } else if (typeof $event == "object" && $event.id) {
        const ix = this.selectedData.values.indexOf($event.id);
        if (ix >= 0) {
          this.selectedData.values.splice(ix, 1);
        } else {
          this.selectedData.values = [
            ...this.selectedData.values,
            ...[$event.id]
          ];
        }
      }
    }
  },
  created() {
    if (this.dataList.length)
      this.selectedData.values.push(this.dataList[0].id);
  }
};
</script>

<style scoped>
section {
  position: relative;
  clear: both;
  text-align: left;
  margin: -15px;
}

.nav-tabs-custom {
  margin-bottom: 0;
}

.footer {
  min-height: 40px;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
}

.code-container {
  position: relative;
}

.code-container > .toolbar {
  position: absolute;
  right: 5px;
  top: 0;
  display: inline-block;
}

.code-container > .toolbar > .btn {
  font-size: 12pt;
}

form.inline-form {
  position: relative;
  margin-bottom: 15px;
  min-height: 32px;
  clear: both;
}

form.inline-form,
select.form-control.form-control-inline,
form.inline-form,
input.form-control {
  min-width: 200px;
  margin-left: 10px;
  width: calc(100% - 30px);
  display: inline-block;
}
form.inline-form,
div.radio {
  padding-top: 8px;
}

label.checkbox-inline {
  margin-right: 10px;
  white-space: nowrap;
}
label > .form-group {
  display: inline-block;
  margin-bottom: 0;
  padding: 0 30px 0 0;
}
pre {
  min-height: 200px;
}

pre.short {
  min-height: auto;
}
.tab-content {
  min-height: 400px;
  max-height: calc(100dvh - 300px);
  overflow: auto;
}
.tab-pane {
  margin: 10px;
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.skin-dark .btn.text-warning {
  color: var(--skin-dark-light);
}

.skin-dark .pull-right.text-primary {
  color: var(--skin-dark-light);
}

.skin-dark .checkbox-inline .btn.btn-xs {
  color: var(--skin-dark-light);
}

.skin-dark .checkbox-inline .btn.btn-xs:hover {
  color: #fff;
}

</style>
